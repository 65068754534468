import React from "react";
import Layout from "../../components/layout";
import "./style.scss";

const Secrets = () => (
  <Layout>
    <h2>Secrets</h2>
    <h3>Exercise</h3>
    <a href="/bigger/excercise-form.pdf">Form</a>
    <h3>React</h3>
    <a href="http://projects.wojtekmaj.pl/react-lifecycle-methods-diagram/">
      Life cycle diagram
    </a>
    <h3>GraphQL</h3>
    <a href="https://devhints.io/graphql#schema">Schema Cheat Sheet</a>
    <h3>Develop flow</h3>
    <pre>git checkout develop</pre>
    <pre>git checkout -b feature/featurename develop </pre>
    <pre>git add . </pre>
    <pre>git commit -m "commit message" </pre>
    <pre>git checkout develop </pre>
    <pre>git merge feature/featurename </pre>
    <pre>git push </pre>
    <h3>Release flow</h3>
    <pre>cd /project_root</pre>
    <pre>git checkout -b release/x.x.x develop </pre>
    <pre>git push -u origin release/x.x.x </pre>
    <pre>
      {"//"} The code is now in Git and can be moved to a staging environment{" "}
    </pre>
    <pre>git add . </pre>
    <pre>git commit -m "release message" </pre>
    <pre>git checkout develop </pre>
    <pre>git merge release/x.x.x </pre>
    <pre>git push </pre>
    <pre>git checkout master </pre>
    <pre>git merge release/x.x.x </pre>
    <pre>git push </pre>
    <pre>git tag vX.X.X </pre>
    <pre>git push origin vX.X.X </pre>
    <pre>git branch -d feature/featurename</pre>
  </Layout>
);

export default Secrets;
